import React from "react";
import { Routes, Route, Navigate  } from "react-router-dom";
import './App.css';
import routes from './route';
import Chatbot from './chatbot/chatbot';


export default function App() {
  // const { pathname } = useLocation();
  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });
  return (
    <div className="App">
      <Routes>
      {getRoutes(routes)}
        <Route path="/" component={< Chatbot/>} />
        <Route path="/domain.html" component={< Chatbot/>} />
        {/* <Route path="*" element={<Navigate to="*" />} /> */}
      </Routes>
    </div>
  );
}



