import * as React from 'react';
import { useState , useRef } from 'react';
import { Grid } from '@mui/material';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { List, ListItem } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import CloseIcon from '@mui/icons-material/Close';
import chatboticon2 from './images/Bot 2.png'
import chatboticon3 from './images/Bot 3.png'
import chatbotlogo from './images/MINEMITRA Logo.png'
import chatboticon1 from './images/Bot 1.png'
import buttonicon from './images/button icon.png'
import translatebutton from './images/tranalate-button.png'
import sendbutton from './images/send-button.png'
import chatboticon4 from './images/Bot 4.png'
import StatusofApplicationIcon from './images/Status of Application Icon.png'
import UpdateMineralPricesIcon from './images/Update Mineral Prices Icon.png'
import AboutMineMitraIcon from './images/About MineMitra Icon.png'
import PayOnlineforleaseIcon from './images/Pay Online for lease Icon.png'
import MineralRetailerRegistrationIcon from './images/Mineral Retailer Registration Icon.png'
import ApprovalofMiningPlanIcon from './images/Approval of Mining Plan Icon.png'
import { makeStyles } from '@mui/styles';
import translations from './translations.json';
import axios from "axios";


const useStyles = makeStyles((theme) => ({
    TextField: {
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#DCDCDC',
        },
        '&:hover fieldset': {
          borderColor: '#ffffff',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#ffffff',
        },
        '& .MuiInputBase-input::placeholder': {
            color: '#ffffff',
          },
          '& .MuiInputBase-input::-webkit-input-placeholder': {
            color: '#ffffff',
          },
          '& .MuiInputBase-input:-moz-placeholder': {
            color: '#ffffff',
          },
          '& .MuiInputBase-input::-moz-placeholder': {
            color: '#ffffff',
          },
          '& .MuiInputBase-input:-ms-input-placeholder': {
            color: '#ffffff',
          },
      },
    },
    TextField1: {
      '& .MuiOutlinedInput-root': {
        borderRadius: "50px",
        backgroundColor: "#fff",
        '& fieldset': {
          borderColor: 'grey',
        },
        '&:hover fieldset': {
          borderColor: 'grey',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'grey',
        },
      },
    },
    TextField2: {
      '& .MuiOutlinedInput-root': {
        backgroundColor: "#fff",
        '& fieldset': {
          borderColor: '#c5392e',
        },
        '&:hover fieldset': {
          borderColor: '#c5392e',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#c5392e',
        },
      },

    },
    Button: {
        background: 'linear-gradient(#fff 30%, #FFF 90%)',
        borderRadius: 3,
        height: 48,
      },
      Button1: {
        background: 'linear-gradient(#c5392e 30%, #c5392e 90%)',
        borderRadius: 3,
        height: 48,
      },
      Button2: {
        background: 'linear-gradient(#fff 30%, #FFF 90%)',
      },
      Button3: {
        background: 'linear-gradient(#c5392e 30%, #c5392e 90%)',
        borderRadius: 3,
        height: 48,
      }
  }));
  
export default function ResponsiveDialog() {

const classes = useStyles()
const [showChatbot, setShowChatbot] = useState(false);
const [showChatbotintro, setshowChatbotintro] = useState(false)
const [startChatbot, setStartChatbot] = useState(false);
const [startChatbot1,setStart1Chatbot] = useState(false);
const [showPopup, setShowPopup] = useState(false);
const [nameerror,setNameerror] = useState(false)
const [emailerror,setEmailerror] = useState(false)
const [phonenoerror,setPhonenoerror] = useState(false)
const [districterror,setDistricterror] = useState(false)
const [emptyinputname, setEmptyinputname] = useState(true)
const [emptyinputemail, setEmptyinputemail] = useState(true)
const [emptyinputphone, setEmptyinputphone] = useState(true)
const [emptyinputdisrtict, setEmptyinputdistrict] = useState(true)
const [applicationno,setApplicationno] = useState('')
const [modistrict,setModistrict] = useState ('')
const [input, setInput] = useState('')
const [name,setName] = useState('')
const [email,setEmail] = useState('')
const [phone,setPhone] =useState('')
const [district,setDistrict] = useState('')
const [message,setMessage] = useState({})
const [messages,setMessages] = useState([])
const[loading , setLoading] = useState([])
const [formData, setFormData] = useState({
  name: "null",
  email: "null",
  mobile: "null",
  district: "null",
});
const [messageData, setMessageData] = useState({
  user_id:"",
  name: "",
  email: "",
  mobile: "",
  district: "",
  previousQuery: "None",
  langCode:"english",
  option: "False",
  query: "",
  applicationno:"",
  modistrict:"",
});


  const handleClickOpen = () => {
    setShowChatbot(!showChatbot)
    setshowChatbotintro (true)
    setStartChatbot(false)
    setStart1Chatbot(false)
    setMessages([])
    setEmptyinputemail(true)
    setEmptyinputname(true)
    setEmptyinputphone(true)
    setEmptyinputdistrict(true)
    setMessageData({ ...messageData, langCode: "english" })
    setFormData({name:"null",email:"null",mobile:"null",district:"null"})

  };

  // cloud
  // axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
  // axios.defaults.xsrfCookieName = "csrftoken";  
  // const csrfToken = document.cookie.split(';').find(c => c.trim().startsWith('csrftoken=')).split('=')[1];
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  const csrftoken = getCookie('csrftoken'); // get CSRF token from cookie or state
  axios.defaults.headers.common['X-CSRFToken'] = csrftoken;

  const startChat = (e) => {
    setScrolled(true)
    setShowChatbot(true)
    console.log(formData)
    console.log(messageData)
    // setshowChatbotintro (false)
    // setStartChatbot(true)
    e.preventDefault();
    axios
      .post("https://minemitra.swar.live/bot/1/", JSON.stringify(formData), {
        headers: { 
          "Content-Type": "application/json" ,
          // // cloud
          // "X-CSRFTOKEN": csrfToken
        }
      })
      .then((response) => {
        console.log(response.data);
        setshowChatbotintro (false)
        setStartChatbot(true)
        setMessageData({ ...messageData, user_id: response.data.user_id})
        // do something with the response data
      })
      .catch((error) => {
        console.error("Error:", error);
        setshowChatbotintro (true)
        setStartChatbot(false)
      });
  };

  const startChat1 = () =>{
      setScrolled(true)
      setShowChatbot(true)
      setStartChatbot(false)
      setStart1Chatbot(true)
  }

  const showpopup = () => {
    setScrolled(true)
    setShowChatbot(true)
    setStart1Chatbot(true)
    setShowPopup(!showPopup)
  }


  const nameBlur = () =>{
    namevalidation(name)

  }
    const namevalidation = (name) => {
    name !== '' ? setEmptyinputname(false) : setEmptyinputname(true)
    // setFormData({ ...formData, name: name })
    // setMessageData({ ...messageData, name: name })
    // console.log(formData)
    const nameRegex = /^[a-zA-Z ]*$/;
    if (!nameRegex.test(name)) {
      setNameerror(true);
    } else {
      setNameerror(false);
    }
  }

  const emailBlur = () =>{
    emailvalidation(email)
  }

  const emailvalidation = (email1) =>{
    email !== '' ? setEmptyinputemail(false) : setEmptyinputemail(true)
    // setFormData({ ...formData, email: email1 })
    // setMessageData({ ...messageData, email: email1 })
    // console.log(formData)
    const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!emailRegex.test(email1)) {
      setEmailerror(true);
    } else {
      setEmailerror(false);
    }
  }

  const phoneBlur = () => {
    phonenovalidation(phone)
  }

  const phonenovalidation = (phone1) => {
    phone !== '' ? setEmptyinputphone(false) : setEmptyinputphone(true)
    // setFormData({ ...formData, mobile: phone1 })
    // setMessageData({ ...messageData, mobile: phone1 })
    // console.log(formData)
    const phoneRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (!phoneRegex.test(phone1)) {
      setPhonenoerror(true);
    } else {
      setPhonenoerror(false);
    }
    
  }

  // const districtBlur = () =>{
  //   disrictvalidation(district)
  // }

  const disrictChange = (event) => {
    setDistrict(event.target.value)
    event.target.value !== '' ? setEmptyinputdistrict(false) : setEmptyinputdistrict(true)
    // setFormData({ ...formData, district: event.target.value })
    // setMessageData({ ...messageData, district: event.target.value })
    // console.log(formData)
    console.log(district)
    const districtRegex = /^[a-zA-Z]+$/;
    if (!districtRegex.test(district)) {
      setDistricterror(true);
    } else {
      setDistricterror(false);
    }
    // auto fill validation
    namevalidation(name)
    phonenovalidation(phone)
    emailvalidation(email)
    setTimeout(
      () => datacollect(name,email,phone,event.target.value), 
      1500
    );
  }

  const datacollect = (name1,email1,phone1,district1) => {
    setFormData({
      ...formData,
      name: name1,
      email: email1,
      mobile: phone1,
      district: district1,
    });
    // console.log(formData)
    setMessageData({
      user_id:"",
      name: name1,
      email: email1,
      mobile: phone1,
      district: district1,
      previousQuery: "None",
      langCode:"english",
      option: "False",
      query: "",
      applicationno:"",
      modistrict:"",
    });
    // console.log(messageData)
  }
  

  const ServicesforCitizen = (e) =>{
    setScrolled(false)
    let chatquery = {
      type: "request",
      content : translations[language].Services_for_Citizen
    }
    let chatsentdata = {
      ...messageData,
      option: "true",
      query: translations[language].Services_for_Citizen
    }
    setMessages(existing =>([...existing,chatquery]))
    let loadingquery = {
      type: "loading",
      content : "...",
    }
    setLoading([loadingquery])
    e.preventDefault();
      axios
        .post("https://minemitra.swar.live/bot/1/response/", JSON.stringify(chatsentdata), {
          headers: { 
            "Content-Type": "application/json" ,
            // // cloud
          // "X-CSRFTOKEN": csrfToken
          }
        })
        .then((response) => {
          setScrolled(false)
          setLoading([])
          console.log(response.data);
          // do something with the response data
          let chatresponse = {
            type: "response",
            content: response.data.responses,
            menus: response.data.menus,
            // menus: response.data.menus,
            links: response.data.links,
          }
          setMessages(existing =>([...existing,chatresponse]))
        })
        .catch((error) => {
          console.error("Error:", error);
        });
  }
 
  const MineralManagment = (e) =>{
    setScrolled(false)
    let chatquery = {
      type: "request",
      content : translations[language].Mineral_Managment
    }
    let chatsentdata = {
      ...messageData,
      option: "true",
      query: translations[language].Mineral_Managment
    }
    setMessages(existing =>([...existing,chatquery]))  
    let loadingquery = {
      type: "loading",
      content : "...",
    }
    setLoading([loadingquery])
    e.preventDefault();
    axios
      .post("https://minemitra.swar.live/bot/1/response/", JSON.stringify(chatsentdata), {
        headers: { 
          "Content-Type": "application/json" ,
          // // cloud
          // "X-CSRFTOKEN": csrfToken
        }
      })
      .then((response) => {
        setScrolled(false)
        setLoading([])
        console.log(response.data.menus);
        // do something with the response data
        let chatresponse = {
          type: "response",
          content: response.data.responses,
          menus: response.data.menus,
          // menus: response.data.menus,
          links: response.data.links
        }
        setMessages(existing =>([...existing,chatresponse]))
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const MineralMART = (e) =>{
    setScrolled(false)
    let chatquery = {
      type: "request",
      content : translations[language].Mineral_MART
    }
    let chatsentdata = {
      ...messageData,
      option: "true",
      query: translations[language].Mineral_MART
    }
    setMessages(existing =>([...existing,chatquery])) 
    let loadingquery = {
      type: "loading",
      content : "...",
    }
    setLoading([loadingquery])   
    e.preventDefault();
    axios
      .post("https://minemitra.swar.live/bot/1/response/", JSON.stringify(chatsentdata), {
        headers: { 
          "Content-Type": "application/json" ,
          // // cloud
          // "X-CSRFTOKEN": csrfToken
        }
      })
      .then((response) => {
        setScrolled(false)
        setLoading([])
        console.log(response.data);
        console.log(response.data.links)
        // do something with the response data
        let chatresponse = {
          type: "response",
          content: response.data.responses,
          menus: response.data.menus,
          // menus: response.data.menus,
          links: response.data.links
        }
        setMessages(existing =>([...existing,chatresponse]))
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  const menubox = (e) =>{
    setScrolled(false)
    let chatquery = {
      type: "request",
      content : e.target.value
    }
    let chatsentdata = {
      ...messageData,
      option: "true",
      query: e.target.value,
    }
    setMessages(existing =>([...existing,chatquery]))
    let loadingquery = {
      type: "loading",
      content : ". . .",
    }
    setLoading([loadingquery])
    axios
      .post("https://minemitra.swar.live/bot/1/response/", JSON.stringify(chatsentdata), {
        headers: { 
          "Content-Type": "application/json" ,
          // // cloud
          // "X-CSRFTOKEN": csrfToken
        }
      })
      .then((response) => {
        setScrolled(false)
        setLoading([])
        console.log(response.data);
        // do something with the response data
        let chatresponse = {
          type: "response",
          content: response.data.responses,
          menus: response.data.menus,
          responsefaq: response.data.response_faq,
          responsefaq1: response.data.response_faq1,
          responsefaqstatus: response.data.response_faq_status,
          responsefaqlink: response.data.faq_link,
          // menus: response.data.menus,
          links: response.data.links,
          documentstatus:response.data.document_status
        }
        setMessages(existing =>([...existing,chatresponse]))
        if(response.data.form){
          let chatquery1 = {
            type: "request",
            content : '',
            applicationformstatus : true,
            moformstatus : false
          }
          console.log(chatquery1)
          setMessages(existing =>([...existing,chatquery1]))
          console.log(messages)
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }


  const sendmessageinput =  (event) =>{
    console.log("cd")
      let chatquery = {
        type: "request",
        content : event.target.value,
        formstatus : false
      }
      setInput(event.target.value)
      console.log(chatquery)
      setMessage(chatquery)
      setMessageData({ ...messageData, query: event.target.value })
  }


  const dataSent = (e) => {
    if(input === '')
    {
      window.alert("Please enter the question to be asked")
    }
    else
    {
      setScrolled(false)
      // if(!applicationstatus){
      setMessages(existing =>([...existing,message]))
      let loadingquery = {
        type: "loading",
        content : "...",
      }
      setLoading([loadingquery])
      console.log(messages)
      if(input === "Whether government orders are available online?" ||input === "whether government orders are available online" ||input === "whether government orders are available online?" || input === "Whether government orders are available online" || input === "Are government orders available online?" || input === "Are government orders available online" || input === "are government orders available online?" || input === "are government orders available online" || input === "क्या खनन से सम्बन्धित शासनादेश ऑनलाइन उपलब्ध है ?" || input === "क्या खनन से सम्बन्धित शासनादेश ऑनलाइन उपलब्ध है")        {
          setLoading([])
          let chatresponse = {
            type: "response",
            response2text: true,
            content: "",
            responsefaq: "",
            responsefaq1: "",
            responsefaqstatus: false,
            responsefaqlink: [],
            menus: [],
            links: [],
            applicationformstatus: false,
            moformstatus: false,
            documentstatus:false
          }
          setMessages(existing =>([...existing,chatresponse]))
          setMessage({content:""})
          setInput('')
          return
        }
      e.preventDefault();
      axios
        .post("https://minemitra.swar.live/bot/1/response/", JSON.stringify(messageData), {
          headers: { 
            "Content-Type": "application/json" ,
            // // cloud
            // "X-CSRFTOKEN": csrfToken
          }
        })
        .then((response) => {
          setScrolled(false)
          setLoading([])
          let chatresponse = {
            type: "response",
            content: response.data.responses,
            responsefaq: response.data.response_faq,
            responsefaq1: response.data.response_faq1,
            responsefaqstatus: response.data.response_faq_status,
            responsefaqlink: response.data.faq_link,
            menus: response.data.menus,
            // menus: response.data.menus,
            links: response.data.links,
            applicationformstatus: response.data.form,
            moformstatus: response.data.form_mo,
            documentstatus:response.data.document_status
          }
            setMessages(existing =>([...existing,chatresponse]))
            if(response.data.form ){
              let chatquery1 = {
                type: "request",
                content : '',
                applicationformstatus : true,
                moformstatus:false
              }
              console.log(chatquery1)
              setMessages(existing =>([...existing,chatquery1]))
              console.log(messages)
            }
              if(response.data.form_mo ){
                let chatquery1 = {
                  type: "request",
                  content : '',
                  applicationformstatus : false,
                  moformstatus : true
                }
                console.log(chatquery1)
                setMessages(existing =>([...existing,chatquery1]))
                console.log(messages)
              }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
        setMessage({content:""})
        setInput('')
    }
  }

  const [language, setLanguage] = useState('en');
  const handleLanguageChange = () => {
    setLanguage(language === 'en' ? 'hi' : 'en');
   language === 'en' ? setMessageData({ ...messageData, langCode: "hindi" }) : setMessageData({ ...messageData, langCode: "english" })
  };

  const chatHistoryRef = useRef(null);

  const [scrolled, setScrolled] = useState(false);

  if (!scrolled) {
    setTimeout(() => {
      chatHistoryRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
      setScrolled(true);
    }, 0);
  }

  const imagebox1 = () => {
    setScrolled(false)
    setShowChatbot(true)
    setStart1Chatbot(true)
    setShowPopup(!showPopup)
    let chatquery1 = {
      type: "request",
      content : '',
      applicationformstatus : true,
      moformstatus:false
    }
    console.log(chatquery1)
    setMessages(existing =>([...existing,chatquery1]))
    console.log(messages)
  };

  const imagebox2 = () => {
    window.open('https://www.upmineralmart.com/login ', '_blank');
  };

  const imagebox3 = () => {
    window.open('https://minemitra.up.gov.in/About-hi', '_blank');
  };

  const imagebox4 = () => {
    window.open('http://upmines.upsdc.gov.in/BrickKiln_RegistrationOTP.aspx', '_blank');
  };

  const imagebox5 = () => {
    window.open('https://upminemitra.in/home/KnowMore?encid=tVnxAY4QjsM=', '_blank');
  };

  const imagebox6 = () => {
    window.open('https://upminemitra.in/home/KnowMore?encid=lWAasHR6cOc=', '_blank');
  };

  const applicationstatus = (e) => {
    setScrolled(false)
    let chatsentdata = {
      application_no: applicationno,
    }
    console.log(chatsentdata)
    let loadingquery = {
      type: "loading",
      content : "...",
    }
    setLoading([loadingquery])   
    e.preventDefault();
    axios
      .post("https://minemitra.swar.live/application_status/1/", JSON.stringify(chatsentdata), {
        headers: { 
          "Content-Type": "application/json" ,
          // // cloud
          // "X-CSRFTOKEN": csrfToken
        }
      })
      .then((response) => {
        setScrolled(false)
        setLoading([])
        setApplicationno('')
        let applicationstatusdata = []
        console.log(response.data);
        applicationstatusdata=response.data
        console.log(applicationstatusdata)
        // do something with the response data
        if(applicationstatusdata.length === 0)
        {
          let chatresponse = {
            type: "response",
            content: "Please enter the correct Appilcation Number",
            menus: [],
            links: response.data.links,
            moformstatus : false
          }
          setMessages(existing =>([...existing,chatresponse]))
          let chatquery1 = {
            type: "request",
            content : '',
            applicationformstatus : true,
            moformstatus : false
          }
          console.log(chatquery1)
          setMessages(existing =>([...existing,chatquery1]))
          console.log(messages)
        }
        else{
        let chatresponse = {
          type: "response",
          content: "",
          ApplicationDate: response.data.ApplicationDate,
          ApplicationNumber: response.data.ApplicationNumber,
          ApplicantName: response.data.ApplicantName,
          TypeOfApplication : response.data.TypeOfApplication,
          ApplicationStatus: response.data.ApplicationStatus,
          ApplicationEnd: response.data.ApplicationEnd,
          menus: [],
          links: response.data.links,
          applicationtrue: true
        }
        setMessages(existing =>([...existing,chatresponse]))
      }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const Modistrict = (e) => {
    setScrolled(false)
    let chatsentdata = {
      distname: modistrict,
    }
    console.log(chatsentdata)
    let loadingquery = {
      type: "loading",
      content : "...",
    }
    setLoading([loadingquery])   
    e.preventDefault();
    axios
      .post("https://minemitra.swar.live/mo_officer/1/", JSON.stringify(chatsentdata), {
        headers: { 
          "Content-Type": "application/json" ,
          // // cloud
          // "X-CSRFTOKEN": csrfToken
        }
      })
      .then((response) => {
        setScrolled(false)
        setLoading([])
        setModistrict('')
        let modistrictdata = []
        console.log(response.data);
        modistrictdata=response.data
        console.log(modistrictdata)
        // do something with the response data
        if(modistrictdata.length === 0)
        {
          let chatresponse = {
            type: "response",
            content: "Please enter the correct District name",
            menus: [],
            links: response.data.links,
            modistricts: false
          }
          setMessages(existing =>([...existing,chatresponse]))
          let chatquery1 = {
            type: "request",
            content : '',
            applicationformstatus : false,
            moformstatus : true
          }
          console.log(chatquery1)
          setMessages(existing =>([...existing,chatquery1]))
          console.log(messages)
        }
        else{
        let chatresponse = {
          type: "response",
          content: "",
          Designation: response.data.Designation,
          DistrictName: response.data.DistrictName,
          OfficerName: response.data.OfficerName,
          Email: response.data.Email,
          MobileNo : response.data.MobileNo,
          menus: [],
          links: response.data.links,
          modistricts: true
        }
        setMessages(existing =>([...existing,chatresponse]))
      }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const changelink = (event) => {
      if (event.target.checked) {
        window.open('https://upminemitra.in/home/KnowMore?encid=Fl7mhn55ZbU=', '_blank');
      }
  }
  const changelink1 = (event) => {
    if (event.target.checked) {
      window.open('https://upminemitra.in/home/KnowMore?encid=hQW6om9EW8g=', '_blank');
    }
 }
const changelink2 = (event) => {
  if (event.target.checked) {
    window.open(' https://upminemitra.in/home/KnowMore?encid=Yt+SPMuLHS0=', '_blank');
  }
 }
const changelink3 = (event) => {
  if (event.target.checked) {
    window.open('https://upminemitra.in/home/KnowMore?encid=+PCvsubWRnU=', '_blank');
  }
 }
 const changelink4 = (event) => {
  if (event.target.checked) {
    window.open('https://upminemitra.in/home/KnowMore?encid=NGJoxb2UnKI=', '_blank');
  }
 }
 const changelink5 = (event) => {
  if (event.target.checked) {
    window.open('https://upminemitra.in/home/KnowMore?encid=NGJoxb2UnKI=', '_blank');
  }
 }
 const changelink6 = (event) => {
  if (event.target.checked) {
    window.open('https://upminemitra.in/home/KnowMore?encid=yapduPuqsudhirH4=', '_blank');
  }
 }
 const changelink7 = (event) => {
  if (event.target.checked) {
    window.open('https://upminemitra.in/home/KnowMore?encid=tVnxAY4QjsM=', '_blank');
  }
 }
 const changelink8 = (event) => {
  if (event.target.checked) {
    window.open('https://upminemitra.in/home/KnowMore?encid=B6ybOOPNbYk=', '_blank');
  }
 }
 const changelink9 = (event) => {
  if (event.target.checked) {
    window.open('https://upminemitra.in/home/KnowMore?encid=lWAasHR6cOc=', '_blank');
  }
 }
 const changelink10 = (event) => {
  if (event.target.checked) {
    window.open('http://upmines.upsdc.gov.in/DefaultLease.aspx', '_blank');
  }
 }
 const changelink11 = (event) => {
  if (event.target.checked) {
    window.open('http://upmines.upsdc.gov.in/BrickKiln_RegistrationOTP.aspx', '_blank');
  }
 }

  const entersubmit = (event) => {
    if (event.key === 'Enter') {
      setScrolled(false)
      if(input === '')
      {
        window.alert("Please enter the questions to be asked")
      }
      else 
      {
        let chatquery = {
          type: "request",
          content : input,
          formstatus : false
        }
        setMessage(chatquery)
        setMessageData({ ...messageData, query: input })
        // if(!applicationstatus){
          setMessages(existing =>([...existing,message]))
        console.log(messages)
        let loadingquery = {
          type: "loading",
          content : "...",
        }
        setLoading([loadingquery])
        console.log(messages)
        if(input === "Whether government orders are available online?" ||input === "whether government orders are available online" ||input === "whether government orders are available online?" || input === "Whether government orders are available online" || input === "Are government orders available online?" || input === "Are government orders available online" || input === "are government orders available online?" || input === "are government orders available online" || input === "क्या खनन से सम्बन्धित शासनादेश ऑनलाइन उपलब्ध है ?" || input === "क्या खनन से सम्बन्धित शासनादेश ऑनलाइन उपलब्ध है")
        {
          setLoading([])
          let chatresponse = {
            type: "response",
            response2text: true,
            content: "",
            responsefaq: "",
            responsefaq1: "",
            responsefaqstatus: false,
            responsefaqlink: [],
            menus: [],
            links: [],
            applicationformstatus: false,
            moformstatus: false,
            documentstatus:false
          }
          setMessages(existing =>([...existing,chatresponse]))
          setMessage({content:""})
          setInput('')
          return
        }
        event.preventDefault();
        axios
          .post("https://minemitra.swar.live/bot/1/response/", JSON.stringify(messageData), {
            headers: { 
              "Content-Type": "application/json" ,
              // // cloud
              // "X-CSRFTOKEN": csrfToken
            }
          })
          .then((response) => {
            setScrolled(false)
            setLoading([])
            let chatresponse = {
              type: "response",
              content: response.data.responses,
              responsefaq: response.data.response_faq,
              responsefaq1: response.data.response_faq1,
              responsefaqstatus: response.data.response_faq_status,
              responsefaqlink: response.data.faq_link,
              menus: response.data.menus,
              // menus: response.data.menus,
              links: response.data.links,
              applicationformstatus: response.data.form,
              moformstatus: response.data.form_mo,
              documentstatus:response.data.document_status
            }
            setMessages(existing =>([...existing,chatresponse]))
            if(response.data.form){
            let chatquery1 = {
              type: "request",
              content : '',
              applicationformstatus : true,
              moformstatus : false
            }
            console.log(chatquery1)
            setMessages(existing =>([...existing,chatquery1]))
            console.log(messages)
          }
            if(response.data.form_mo ){
              let chatquery1 = {
                type: "request",
                content : '',
                applicationformstatus : false,
                moformstatus : true
              }
              console.log(chatquery1)
              setMessages(existing =>([...existing,chatquery1]))
              console.log(messages)
            }

          })
          .catch((error) => {
            console.error("Error:", error);
          });
          setMessage({content:""})
          setInput('')
      }
      }

  }

  return (
    <div className={`none ${showChatbot?'body':'none'}`}>
     <Container maxWidth="sm">
      <Box className={` message ${showChatbot?'none':'bounce2'}`} onClick={handleClickOpen} sx={{cursor:"pointer"}}>
      <img src={chatboticon1} className={` ${showChatbot?'chat-icon1':'chat-icon'}`}  alt="open icon" />
      </Box>
       {showChatbot && showChatbotintro && 
            <Box className="chatbot">
              <Box className="chatbot-messages">
                <Box className='chatbot-header'>
                  <Typography variant='h6' style={{fontFamily: '"Roboto Condensed", sans-serif',color:"white" }} >Welcome to </Typography>
                  <Box className='chat-logo-box'>
                    <img src={chatbotlogo} alt='logo' className='chat-logo' />
                  </Box>
                  <Typography variant="h6"style={{fontFamily: '"Roboto Condensed", sans-serif', fontWeight: 'bold', fontSize: '1.8rem',color:"white" }} >CHATBOT V 1.0</Typography>
                </Box>
                    <Box className="chatbot-message" >
                        <Box >
                        <TextField
                           name="name" 
                           type="text" 
                           placeholder="Name" 
                           id="outlined-basic"
                           size="small"
                           className={classes.TextField}
                           fullWidth
                           autoComplete="off"
                           onChange={(event) => setName(event.target.value)}
                           onBlur={nameBlur}
                           inputProps={{
                            style: {
                              height: "18px",
                              color: 'white',
                            },
                          }}
                        />
                        {nameerror &&
                        <FormHelperText style={{ color: 'white' }}>
                          Invalid Name
                        </FormHelperText>}
                        </Box>
                        <Box item xs={12} className='inputfield-gap' >
                        <TextField
                           name="phonenumber" 
                           type="text" 
                           placeholder="Phone Number"
                           id="outlined-basic"
                           size="small"
                           className={classes.TextField}
                           fullWidth
                           autoComplete="off"
                           onChange={(event) => setPhone(event.target.value)}
                           onBlur = {phoneBlur}
                           inputProps={{
                            style: {
                              height: "18px",
                              color: 'white',
                            },
                          }}
                        />
                        {phonenoerror &&
                        <FormHelperText style={{ color: 'white' }}>
                          Invalid Phone Number
                        </FormHelperText>}
                        </Box>
                        <Box item xs={12} className='inputfield-gap' >
                        <TextField
                            name="email" 
                            type="text" 
                            placeholder="Email-ID" 
                            id="outlined-basic"
                           size="small"
                           className={classes.TextField}
                           fullWidth
                           autoComplete="off"
                           onChange={(e) => setEmail(e.target.value)}
                           onBlur={emailBlur}
                           inputProps={{
                            style: {
                              height: "18px",
                              color: 'white',
                            },
                          }}
                        />
                        {emailerror &&
                        <FormHelperText style={{ color: 'white' }}>
                          Invalid Email
                        </FormHelperText>}
                        </Box>
                        <Box item xs={12} className='inputfield-gap' >
                        <TextField 
                           name="districts"
                           type="text" 
                           placeholder="District"
                           id="outlined-basic"
                           size="small"
                           className={classes.TextField}
                           fullWidth
                           autoComplete="off"
                           onChange={disrictChange}
                          //  onBlur={districtBlur}
                           inputProps={{
                            style: {
                              height: "18px",
                              color: 'white',
                            },
                          }}
                        />
                        {districterror &&
                        <FormHelperText style={{ color: 'white' }}>
                          Invalid District
                        </FormHelperText>}
                        </Box>
                        <Box item xs={12} mt={2} >
                        <Button 
                            variant="contained" 
                            onClick={startChat} 
                            fullWidth
                            className={classes.Button}
                            disabled= {(districterror | emailerror | phonenoerror | nameerror |emptyinputdisrtict | emptyinputname  | emptyinputemail | emptyinputphone)}
                            sx={{ 
                                    color:"#c5392e",
                                    height: "35px",
                                    fontWeight: 'bold'
                                }}
                                > 
                                Submit
                        </Button>
                        </Box>
                    </Box>
                </Box>
                <Box className='footer'>
                    <img src={chatboticon2} alt='logo' className="chatbot-icon2" />
                </Box>
            </Box>   
         }
            {showChatbot && startChatbot && 
                <Box className="chatbot1">
                    <Grid className="chatbot-header1" item xs={12} >
                        <Grid item xs={12} mt={4} ml={2}>
                          <Typography variant='h6' style={{fontFamily: '"Roboto Condensed", sans-serif',color:"white"}}  > {translations[language].welcome_to} </Typography>
                          <Box className='chat-logo-box' mb="3px">
                            <img src={chatbotlogo} alt='logo' className='chat-logo' />
                          </Box>
                          <Typography style={{fontFamily: '"Roboto Condensed", sans-serif', fontWeight: 'bold', fontSize: '1.8rem',color:"white" }} >{translations[language].CHATBOT_V}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}  className='center-box1'>
                      <Box className='box1'>
                        <Typography variant='body2' fontFamily="Roboto, sans-serif" textAlign="left" lineHeight="150%" style={{color:"white"}} >{translations[language].about_box}</Typography>
                      </Box>
                    </Grid>
                    <Grid className="chatbot-messages1-img" >
                        <Box className='chatbot-chatarea-img'>
                        <img src={chatboticon2} alt='logo'className='chatbot-icon2' />
                        </Box>
                    </Grid>
                    <Grid className='footer1' item xs={12} >
                        <Box className='inputgetter' >
                            <Grid item xs={2} sx={{cursor:"pointer"}} onClick={handleLanguageChange}>
                                <img src={translatebutton} width="40px" height="40px" alt="translate button"/>
                            </Grid>
                            <Grid item xs={8} width="240px" >
                                <TextField
                                    placeholder={translations[language].write_something} 
                                    id="standard"
                                    size="small"
                                    onClick={startChat1}
                                    fullWidth
                                    className={classes.TextField1}
                                />
                            </Grid>
                            <Grid item xs={2} sx={{cursor:"pointer"}} onClick={dataSent} mr={1} >
                                <img src={sendbutton} width="40px" height="40px" alt="send button"/>
                            </Grid>
                        </Box>
                    </Grid>
                </Box>
            }
            {showChatbot && startChatbot1 && 
                <Box className="chatbot1">
                    <Grid className="chatbot-header2" item xs={12}>
                        <Grid item xs={12} mt={3} sx={{display:'flex',flexDirection:'row'}}>
                          <Box mr={2}>
                            <img src={chatboticon3} alt='logo' height="70px" width="180px"/>
                          </Box>
                          <Box sx={{display:'flex',flexDirection:'column'}}>
                            <Box mt={-2} ml={5} sx={{cursor:"pointer"}} onClick={showpopup}>
                              <img src={buttonicon} alt='logo' height="30px" width="80px" />
                            </Box>
                            <Box mt="5px">
                              <img src={chatbotlogo} alt='logo' height="30px" width="120px" />
                            </Box>
                          </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} mt={15} className='box2'>
                        <Box px={2} py={1} borderRight="1px solid rgba(236, 235, 235, 0.77)" onClick={ServicesforCitizen} sx={{cursor:"pointer"}}>
                          <Typography variant='body2' fontFamily="Roboto, sans-serif" textAlign="center" cursor="pointer" style={{fontWeight: 'bold' }}>{translations[language].Services_for_Citizen}</Typography>
                        </Box>
                        <Box px={2} py={1} borderRight="1px solid rgba(236, 235, 235, 0.77)" onClick={MineralManagment} sx={{cursor:"pointer"}}>
                          <Typography variant='body2' fontFamily="Roboto, sans-serif" textAlign="center" style={{fontWeight: 'bold' }}>{translations[language].Mineral_Managment}</Typography>
                        </Box>
                        <Box px={2} py={1} onClick={MineralMART} sx={{cursor:"pointer"}}>
                          <Typography variant='body2' fontFamily="Roboto, sans-serif" textAlign="center" style={{fontWeight: 'bold' }}>{translations[language].Mineral_MART}</Typography>
                        </Box>
                    </Grid>
                    <Grid className="chatbot-messages1" >
                        <Box className='chatbot-chatarea'>
                            <Box className='chat-left'>
                              <Box className='triangle-left'></Box>
                              <Box className='chat-message-received'>
                                <img src={chatboticon4} alt="icon" width="30px" height="30px" />
                                <Typography variant='body2' ml={2} mt={-2} mb={1} px={3}>Namaste, how can I help you today?</Typography>
                              </Box>
                            </Box>
                              <Box className='linespace'></Box>
                                <Box className='chat' my={2}>
                                  {Array.from(messages).map((item , index) =>
                                   item.type === "request" ? 
                                   (
                                     <><Box className='chat-right' key={index}>
                                      <Box className='chat-message-sent'>
                                        <Box>
                                          <Typography variant='body2' ml={1} my={1} px={3}>{item.content}</Typography>
                                        </Box>
                                        { item.applicationformstatus &&
                                        <Box className='form'>
                                          <TextField 
                                            label="Application-no"
                                            type="text" 
                                            placeholder="Application-no" 
                                            id="outlined-basic"
                                            size="small"
                                            className={classes.TextField2}
                                            fullWidth
                                            autoComplete="off"
                                            onChange={(e) => setApplicationno(e.target.value)}
                                            inputProps={{
                                              style: {
                                                // width:"100px",
                                                height: "20px",
                                                color: 'grey',
                                              },
                                            }}
                                            InputLabelProps={{
                                              style:{
                                                color: '#c5392e'
                                              }
                                            }}
                                          />
                                          {/* <br /> */}
                                          <Button 
                                            variant="contained" 
                                            onClick={applicationstatus} 
                                            fullWidth
                                            className={classes.Button3}
                                            disabled = {applicationno  === '' ? true : false}
                                            sx={{ 
                                                  width:"100px",
                                                  color:"white",
                                                  height: "30px",
                                                  fontSize: "12px"
                                                  // fontWeight: 'bold'
                                                }}
                                              > 
                                                Send
                                          </Button>
                                        </Box>
                                        }
                                        { item.moformstatus &&
                                        <Box className='form'>
                                          <TextField 
                                            label="MO-district"
                                            type="text" 
                                            placeholder="District" 
                                            id="outlined-basic"
                                            size="small"
                                            className={classes.TextField2}
                                            fullWidth
                                            autoComplete="off"
                                            onChange={(e) => setModistrict(e.target.value)}
                                            inputProps={{
                                              style: {
                                                // width:"100px",
                                                height: "20px",
                                                color: 'grey',
                                              },
                                            }}
                                            InputLabelProps={{
                                              style:{
                                                color: '#c5392e'
                                              }
                                            }}
                                          />
                                          {/* <br /> */}
                                          <Button 
                                            variant="contained" 
                                            onClick={Modistrict} 
                                            fullWidth
                                            className={classes.Button3}
                                            disabled = {modistrict === '' ? true : false}
                                            sx={{ 
                                                  width:"100px",
                                                  color:"white",
                                                  height: "30px",
                                                  fontSize: "12px"
                                                  // fontWeight: 'bold'
                                                }}
                                              > 
                                                Send
                                          </Button>
                                        </Box>
                                        }
                                      </Box>
                                      <Box className='triangle-right'></Box>
                                    </Box><Box className='linespace'></Box></>
                                  ):
                                  ( 
                                    <><Box className='chat-left' key={index}>
                                      <Box className='triangle-left'></Box>
                                      <Box className='chat-message-received'>
                                        <img src={chatboticon4} alt="icon" width="30px" height="30px" />
                                        <Typography variant='body2' ml={2} mt={-2} mb={2} px="15px" sx={{fontFamily: 'Sans-serif'}}>
                                          {item.content}
                                          <Link href={item.links} underline="none" target="_blank" rel="noopener noreferrer" sx={{fontFamily: 'Sans-serif'}}>{item.links}</Link>
                                        </Typography>
                                        { item.applicationtrue &&
                                        <>
                                          <Typography variant='body1' ml={2} mt={-2} mb={0} px="15px" sx={{fontFamily: 'Sans-serif'}}>Application Details</Typography>
                                          <Typography variant='body2' ml={2} mt="3px" mb={0} px="15px" sx={{fontSize: "13px", fontFamily: 'Sans-serif'}}>Application Number: {item.ApplicationNumber}</Typography>
                                          <Typography variant='body2' ml={2} mt="3px" mb={0} px="15px" sx={{fontSize: "13px", fontFamily: 'Sans-serif'}}>Application Date: {item.ApplicationDate}</Typography>
                                          <Typography variant='body2' ml={2} mt="3px" mb={0} px="15px" sx={{fontSize: "13px", fontFamily: 'Sans-serif'}}>Applicant Name: {item.ApplicantName}</Typography>
                                          <Typography variant='body2' ml={2} mt="3px" mb={0} px="15px" sx={{fontSize: "13px", fontFamily: 'Sans-serif'}}>Type Of Application: {item.TypeOfApplication}</Typography>
                                          <Typography variant='body2' ml={2} mt="3px" mb={0} px="15px" sx={{fontSize: "13px", fontFamily: 'Sans-serif'}}>Application Status: {item.ApplicationStatus}</Typography>
                                          <Typography variant='body2' ml={2} mt="3px" mb={0} px="15px" sx={{fontSize: "13px", fontFamily: 'Sans-serif'}}>Application End: {item.ApplicationEnd}</Typography>
                                        </>
                                        }
                                        { item.modistricts &&
                                        <>
                                          <Typography variant='body1' ml={2} mt={-2} mb={0} px="15px" sx={{fontFamily:'Sans-serif'}} >MO {item.DistrictName} Details</Typography>
                                          <Typography variant='body2' ml={2} mt="3px" mb={0} px="15px" sx={{fontSize: "13px", fontFamily: 'Sans-serif'}}>Designation: {item.Designation}</Typography>
                                          <Typography variant='body2' ml={2} mt="3px" mb={0} px="15px" sx={{fontSize: "13px", fontFamily: 'Sans-serif'}}>District Name: {item.DistrictName}</Typography>
                                          <Typography variant='body2' ml={2} mt="3px" mb={0} px="15px" sx={{fontSize: "13px", fontFamily: 'Sans-serif'}}>Email: {item.Email}</Typography>
                                          <Typography variant='body2' ml={2} mt="3px" mb={0} px="15px" sx={{fontSize: "13px", fontFamily: 'Sans-serif'}}>Mobile No: {item.MobileNo}</Typography>
                                        </>
                                        }
                                        <Box>
                                          {item.responsefaqstatus &&
                                          <>
                                            {item.responsefaq.split("#").map((item,index) =>
                                              <Typography variant='body2' ml={2} mt="-20px" px="15px" key={index} sx={{lineHeight: "20px", fontFamily: 'Sans-serif'}}>
                                                {item}
                                                <Box height="5px" width ="inherit"></Box><br />
                                              </Typography>
                                            )}
                                            <br />
                                            <Box height="5px" width ="inherit"></Box>
                                            <Box className='chat-respones-link'>
                                              <Link href={item.responsefaqlink} underline="none" target="_blank" rel="noopener noreferrer" sx={{fontFamily: 'Sans-serif'}}>{item.responsefaqlink}</Link>
                                            </Box>
                                            <Box> 
                                              <Typography  variant='body2'  ml={2} my={1} px="15px" sx={{fontFamily: 'Sans-serif'}}>{item.responsefaq1}</Typography>
                                            </Box>
                                            </>
                                          } 
                                        </Box>
                                        <Box>
                                          {item.response2text &&
                                          <>
                                            <Typography variant='body2' ml={2} mt={-2} px="15px" key={index} sx={{fontFamily: 'Sans-serif'}}>
                                            {translations[language].Yes_you_may_visit_our_website}
                                              <Box height="5px" width ="inherit"></Box><br />
                                            </Typography>
                                            <br />
                                            <Box height="5px" width ="inherit"></Box>
                                            <Box className='chat-respones-link'>
                                              <Link href="https://minemitra.up.gov.in/MiningPolicy_hi" underline="none" target="_blank" rel="noopener noreferrer" sx={{fontFamily: 'Sans-serif'}}>https://minemitra.up.gov.in/MiningPolicy_hi</Link>
                                            </Box>
                                            <Box>
                                              <Typography  variant='body2'  ml={2} my={1} px="15px" sx={{fontFamily: 'Sans-serif'}}>{translations[language].To_know_more_about_mining_services_please_our_website}</Typography>
                                            </Box><br/><br/>
                                            <Box className='chat-respones-link'>
                                              <Link href="https://minemitra.up.gov.in/About_hi" underline="none" target="_blank" rel="noopener noreferrer" sx={{fontFamily: 'Sans-serif'}}>https://minemitra.up.gov.in/About_hi</Link>
                                            </Box>
                                            </>
                                          } 
                                        </Box>
                                        <Box>
                                          {item.documentstatus &&
                                          <>
                                          <Typography variant="body1" ml={2} mt={-2} mb={0} sx={{fontFamily:'Sans-serif'}}>{translations[language].Please_select_your_desired_service}</Typography>
                                            <ol class="list">
                                              <List>
                                                <ListItem>
                                                <Typography variant="body1" color="black" sx={{fontFamily:'Sans-serif'}}>1- {translations[language].Online_Permit}</Typography>
                                                </ListItem>
                                                   <ol>
                                                      <List>
                                                        <ListItem>
                                                            <Link  href="https://upminemitra.in/home/KnowMore?encid=Fl7mhn55ZbU=" underline="none" target="_blank" rel="noopener noreferrer" sx={{fontFamily: 'Sans-serif'}}>
                                                            <Typography variant="body2" color="black"><Checkbox onChange={changelink}  size="small" sx={{fontFamily: 'Sans-serif'}}/>
                                                            {translations[language].Krishi_Bhoomi} </Typography>
                                                          </Link>
                                                        </ListItem>
                                                        <ListItem>
                                                          <Link  href="https://upminemitra.in/home/KnowMore?encid=hQW6om9EW8g=" underline="none" target="_blank" rel="noopener noreferrer" sx={{fontFamily: 'Sans-serif'}}>
                                                            <Typography variant="body2" color="black" sx={{fontFamily: 'Sans-serif'}}> <Checkbox onChange={changelink1}  size="small" />
                                                            {translations[language].Niji_Bhoomi} </Typography> 
                                                          </Link>
                                                        </ListItem>
                                                        <ListItem>
                                                          <Link  href="https://upminemitra.in/home/KnowMore?encid=Yt+SPMuLHS0=" underline="none" target="_blank" rel="noopener noreferrer" sx={{fontFamily: 'Sans-serif'}}>
                                                            <Typography variant="body2" color="black" sx={{fontFamily: 'Sans-serif'}}><Checkbox onChange={changelink2} size="small" /> {translations[language].Ordinary_Earth} </Typography>
                                                          </Link>
                                                        </ListItem>
                                                        <ListItem>
                                                          <Link  href="https://upminemitra.in/home/KnowMore?encid=+PCvsubWRnU=" underline="none" target="_blank" rel="noopener noreferrer" sx={{fontFamily: 'Sans-serif'}}>
                                                            <Typography variant="body2" color="black" sx={{fontFamily: 'Sans-serif'}}><Checkbox  onChange={changelink3} size="small" /> {translations[language].BulidingDevelopment_Projects} </Typography>
                                                          </Link>
                                                        </ListItem>
                                                      </List>
                                                    </ol>
                                                <ListItem>
                                                <Typography variant="body1" color="black" sx={{fontFamily: 'Sans-serif'}}>2- {translations[language].Online_Lease} </Typography>
                                                </ListItem>
                                                   <ol>
                                                      <List>
                                                        <ListItem>
                                                          <Link  href="https://upminemitra.in/home/KnowMore?encid=NGJoxb2UnKI=" underline="none" target="_blank" rel="noopener noreferrer" sx={{fontFamily: 'Sans-serif'}}>
                                                            <Typography variant="body2" color="black" sx={{fontFamily: 'Sans-serif'}}><Checkbox onChange={changelink4} size="small" /> {translations[language].Online_LOI} </Typography>
                                                          </Link>
                                                        </ListItem>
                                                        <ListItem>
                                                          <Link  href="https://upminemitra.in/home/KnowMore?encid=NGJoxb2UnKI=" underline="none" target="_blank" rel="noopener noreferrer" sx={{fontFamily: 'Sans-serif'}}>
                                                            <Typography variant="body2" color="black" sx={{fontFamily: 'Sans-serif'}}> <Checkbox onChange={changelink5} size="small" /> {translations[language].Deed_Execution} </Typography>
                                                          </Link>
                                                        </ListItem>
                                                      </List>
                                                    </ol>
                                                <ListItem>
                                                <Typography variant="body1" color="black" sx={{fontFamily: 'Sans-serif'}}>3- {translations[language].Online_License}</Typography>
                                                </ListItem>
                                                   <ol>
                                                      <List>
                                                        <ListItem>
                                                          <Link  href="https://upminemitra.in/home/KnowMore?encid=yapduPuqsudhirH4=" underline="none" target="_blank" rel="noopener noreferrer" sx={{fontFamily: 'Sans-serif'}}>
                                                            <Typography variant="body2" color="black" sx={{fontFamily: 'Sans-serif'}}><Checkbox onChange={changelink6}  size="small" /> {translations[language].Stock_License} </Typography>
                                                          </Link>
                                                        </ListItem>
                                                      </List>
                                                    </ol>
                                                <ListItem>
                                                <Typography variant="body1" color="black" sx={{fontFamily: 'Sans-serif'}}>4- {translations[language].Online_Registration}</Typography>
                                                </ListItem>
                                                    <ol>
                                                      <List>
                                                        <ListItem>
                                                          <Link  href="https://upminemitra.in/home/KnowMore?encid=tVnxAY4QjsM=" underline="none" target="_blank" rel="noopener noreferrer" sx={{fontFamily: 'Sans-serif'}}>
                                                            <Typography variant="body2" color="black" sx={{fontFamily: 'Sans-serif'}}><Checkbox onChange={changelink7} size="small" /> {translations[language].Mineral_Retailer_Registration} </Typography>
                                                          </Link>
                                                        </ListItem>
                                                        <ListItem>
                                                          <Link  href="https://upminemitra.in/home/KnowMore?encid=B6ybOOPNbYk=" underline="none" target="_blank" rel="noopener noreferrer" sx={{fontFamily: 'Sans-serif'}}>
                                                            <Typography variant="body2" color="black" sx={{fontFamily: 'Sans-serif'}}><Checkbox onChange={changelink8} size="small" /> {translations[language].Registration_for_Mining_of_Ordinary_Soil_of_Farmers_Niji_Bhmooi} </Typography>
                                                          </Link>
                                                        </ListItem>
                                                      </List>
                                                    </ol>
                                                <ListItem>
                                                <Typography variant="body1" color="black" sx={{fontFamily: 'Sans-serif'}}>5- {translations[language].Other_Services}</Typography>
                                                </ListItem>
                                                   <ol>
                                                      <List>
                                                        <ListItem>
                                                          <Link  href="https://upminemitra.in/home/KnowMore?encid=lWAasHR6cOc=" underline="none" target="_blank" rel="noopener noreferrer" sx={{fontFamily: 'Sans-serif'}}> 
                                                            <Typography variant="body2" color="black" sx={{fontFamily: 'Sans-serif'}}><Checkbox onChange={changelink9} size="small" /> {translations[language].Approval_of_Mining_Plan} </Typography>
                                                          </Link>
                                                        </ListItem>
                                                        <ListItem>
                                                          <Link  href="http://upmines.upsdc.gov.in/" underline="none" target="_blank" rel="noopener noreferrer" sx={{fontFamily: 'Sans-serif'}}> 
                                                            <Typography variant="body2" color="black" sx={{fontFamily: 'Sans-serif'}}> <Checkbox onChange={changelink10} size="small" /> {translations[language].Online_Payment_of_Lease} </Typography>
                                                          </Link>
                                                        </ListItem>
                                                        <ListItem>
                                                          <Link  href="http://upmines.upsdc.gov.in/" underline="none" target="_blank" rel="noopener noreferrer" sx={{fontFamily: 'Sans-serif'}}>
                                                            <Typography variant="body2" color="black" sx={{fontFamily: 'Sans-serif'}}> <Checkbox onChange={changelink11} size="small" /> {translations[language].Online_Payment_of_Brick_Earth_Kilns} </Typography>
                                                          </Link>
                                                        </ListItem>
                                                      </List>
                                                    </ol>
                                              </List>
                                            </ol>
                                          </>
                                          } 
                                        </Box>
                                        <Box className="menu-box" >
                                        {item.menus.map((item,index) =>
                                          <Box height="fit-content" width="fit-content" onClick={menubox} mb="4px">
                                            <Button variant='contained' 
                                            className={classes.Button2}
                                            key={index}
                                            value={item}
                                            disableElevation
                                            sx={{ 
                                              color:"grey",
                                              borderRadius:"10px",
                                              fontSize: '12px',
                                              width: '240px',
                                              padding: '5px 10px',
                                          }}>
                                              {item}
                                              </Button>
                                          </Box> 
                                          )}
                                        </Box> 
                                      </Box>
                                    </Box><Box  className='linespace'></Box></>
                                    )
                                  )}
                                  {Array.from(loading).map((index) =>

                                    <><Box className='chat-left' key = {index}>
                                    <Box className='triangle-left'></Box>
                                    <Box className='chat-message-received-1'>
                                      <img src={chatboticon4} alt="icon" width="30px" height="30px" />
                                      {/* <Typography variant='h5' ml={2} mt={-2} mb={1} px={3}>
                                        {item.content}
                                      </Typography> */}
                                      <Box className='loadingsymbol'><Box className="dot-flashing"></Box></Box>
                                      </Box>
                                      </Box></>
                                  )}
                                  <Box ref={chatHistoryRef}></Box>  
                              </Box>                   
                        </Box>
                    </Grid> 
                    <Grid className='footer2' item xs={12} >
                      {showChatbot && showPopup ?
                      <><Box class='blacktransparent'>
                         </Box>
                          <Box className='popup-box'>
                          <Box sx={{ float: "right", cursor: "pointer" }} mt={1} mr={1} onClick={showpopup}>
                            <CloseIcon size="small" />
                          </Box>
                          <Box mt={5}>
                            <Typography variant='body1' fontFamily="Roboto, sans-serif" textAlign="center"> {translations[language].What_are_you_looking_for}</Typography>
                          </Box>
                          <Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }} mt={2} px={3}>
                              <Box className='image-box' mr={1} onClick={imagebox1}>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }} mb="5px">
                                  <img src={StatusofApplicationIcon} alt="icon" width="30px" height="35px" />
                                </Box>
                                <Typography fontSize="11px">{translations[language].Know_the_Status_of_your_application}</Typography>
                              </Box>
                              <Box className='image-box' mr={1} onClick={imagebox2}>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }} mb="6px">
                                  <img src={UpdateMineralPricesIcon} alt="icon" width="30px" height="31px" />
                                </Box>
                                <Typography fontSize="11px">{translations[language].Update_Mineral_prices_by_Lessee}</Typography>
                              </Box>
                              <Box className='image-box' onClick={imagebox3}>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }} mb="7px">
                                  <img src={AboutMineMitraIcon} alt="icon" width="30px" height="33px" />
                                </Box>
                                <Typography fontSize="11px">{translations[language].About_MINEMITRA}</Typography>
                              </Box>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }} mt={1} px={3}>
                              <Box className='image-box' mr={1} onClick={imagebox4}>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }} mt="6px" mb="6px">
                                  <img src={PayOnlineforleaseIcon} alt="icon" width="30px" height="37px" />
                                </Box>
                                <Typography fontSize="10px">{translations[language].Pay_online_for_lease_permit_Brick_kilns}</Typography>
                              </Box>
                              <Box className='image-box' mr={1} onClick={imagebox5}>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }} mb="7px">
                                  <img src={MineralRetailerRegistrationIcon} alt="icon" width="30px" height="31px" />
                                </Box>
                                <Typography fontSize="11px">{translations[language].Mineral_Retailer_Registration}</Typography>
                              </Box>
                              <Box className='image-box' onClick={imagebox6}>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }} mb="7px">
                                  <img src={ApprovalofMiningPlanIcon} alt="icon" width="30px" height="33px" />
                                </Box>
                                <Typography fontSize="11px">{translations[language].Approval_of_Mining_Plan}</Typography>
                              </Box>
                            </Box>
                          </Box>
                          <Grid item xs={12} mt={3} px={3}>
                            <Button
                              variant="contained"
                              onClick={showpopup}
                              fullWidth
                              className={classes.Button1}
                              sx={{
                                color: "#fff",
                                height: "40px",
                              }}
                            >
                              Submit
                            </Button>
                          </Grid>
                        </Box></>
                        :
                        <Box className='inputgetter1' mb={1}>
                            <Grid item xs={1} sx={{cursor:"pointer"}} onClick={handleLanguageChange}>
                              <img src={translatebutton} width="40px" height="40px" alt="translate button"/>
                            </Grid>
                            <Grid item xs={10} width="240px" >
                                <TextField
                                    required
                                    placeholder={translations[language].write_something} 
                                    id="standard"
                                    size="small"
                                    onChange={sendmessageinput}
                                    value={message.content}
                                    fullWidth
                                    autoComplete="off"
                                    className={classes.TextField1}
                                    onKeyDown={entersubmit}
                                    inputProps={{ maxLength: 500 }}
                                />
                            </Grid>
                            <Grid item xs={1} sx={{cursor:"pointer"}} onClick={dataSent} mr={1}>
                              <img src={sendbutton} width="40px" height="40px" alt="send button"/>
                            </Grid>
                        </Box>
                         }
                    </Grid>
                </Box>
            }
      </Container>
    </div>
  );
}