import React from "react";
import Chatbot from './chatbot/chatbot'
const routes = [
  {
    route: "/",
    component: <Chatbot />,
  },
  {
    route: "/domain.html",
    component: <Chatbot />,
  },
];

export default routes;
